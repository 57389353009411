import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import { Box, Heading } from 'grommet';

const TermsAndConditions = ({ data }) => (
  <Layout>
    <Head pageTitle={data.termsAndConditionsJson.title} />
    <Box fill="horizontal">
      <Box margin="large" width="xlarge" alignSelf="center">
        <Heading level="1" size="small" responsive={true}>
          OtoSim Inc. Sales Terms & Conditions
        </Heading>
        <br />
        <div
          dangerouslySetInnerHTML={{
            __html:
              data.termsAndConditionsJson.content.childMarkdownRemark.html,
          }}
        />
      </Box>
    </Box>
  </Layout>
);

TermsAndConditions.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TermsAndConditions;

export const query = graphql`
  query termsAndConditionsQuery {
    termsAndConditionsJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
